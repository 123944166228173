import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  keyframes,
} from '@chakra-ui/react'

import { Beacon } from '@saas-ui-pro/onboarding'
import { FiCheck } from 'react-icons/fi'

export type OnboardingWizardProps = object

const fade = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0px, -10px, 0px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`

export const animation = (delay = 0) =>
  `.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ${delay}s 1 normal both running ${fade}`

export interface OnboardingWizardStepProps {
  selected: boolean
  completed: boolean
  title: string | React.ReactNode
  stepNumber?: number
  children: React.ReactNode
}
export const GreenCheck = () => (
  <Icon as={FiCheck} color="green.500" boxSize={'5'} />
)

export const OnboardingWizardStep: React.FC<OnboardingWizardStepProps> = ({
  children,
  stepNumber,
  title,
  selected,
  completed,
}) => {
  const fullyComplete = !selected && completed
  return (
    <Card
      display={!selected && !completed ? 'none' : 'block'}
      width="100%"
      maxW="2xl"
      borderRadius="xl"
      animation={animation(0.2)}
      backgroundColor={fullyComplete ? 'green.600' : 'initial'}
      opacity={fullyComplete ? 0.8 : 1}
    >
      <CardHeader alignItems={'center'} display={'flex'} columnGap={'4'}>
        {selected && <Beacon size="lg" colorScheme="green" />}
        {completed && !selected && <GreenCheck />}
        <Heading as="h2" size="sm" mt={0.5}>
          {title}
        </Heading>
      </CardHeader>

      {selected && <CardBody>{children}</CardBody>}
    </Card>
  )
}

export type OnboardingWizardComponentProps = Omit<
  OnboardingWizardStepProps,
  'title' | 'children'
>
