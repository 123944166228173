import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { Heading, VStack } from '@chakra-ui/react'

import { useMemo } from 'react'
import { animation, OnboardingWizardProps } from './shared'
import { ConnectEnvironmentStep } from './connect-env'

export const OnboardingWizard = (props: OnboardingWizardProps) => {
  const { project } = useCurrentUser()
  const { environments } = project ?? {}

  const hasConnectedLocalEnvironment = useMemo(() => {
    return environments?.some((env) => env.datasources?.length > 0) ?? false
  }, [environments])

  if (!project) return null

  return (
    <>
      <VStack mx={'auto'} spacing="2rem">
        <Heading animation={animation(0)} size="lg">
          Setup {project.name}
        </Heading>
        <ConnectEnvironmentStep
          stepNumber={1}
          selected={!hasConnectedLocalEnvironment}
          completed={hasConnectedLocalEnvironment}
        />
        <ConnectEnvironmentStep
          stepNumber={1}
          selected={false}
          completed={false}
        />
      </VStack>
    </>
  )
}
