import {
  Grid,
  GridItem,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import { FaDiscord, FaGithub } from 'react-icons/fa'

import {
  Page,
  ErrorPage,
  Toolbar,
  ToolbarButton,
  PageBody,
  PageHeader,
  ToolbarDivider,
} from '@saas-ui-pro/react'

// import { IntroTour } from '../components/intro-tour'

import { UsageByTenant } from '../components/metrics/usage-by-tenant'
import { MRR } from '../components/metrics/mrr'
import { Metric, MetricProps } from '../components/metrics/metric'
import { Activity } from '../components/metrics/activity'
import { MetricData, RevenueChart } from '../components/metrics/revenue-chart'
import { useWorkspace } from '@app/features/core/hooks/use-workspace'
import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { eachDayOfInterval } from 'date-fns'
import {
  SegmentedControl,
  DateRangePicker,
  getRangeValue,
  DateRangePresets,
  DateRange,
  getRangeDiff,
} from '@ui/lib'
import { useState } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { OnboardingWizard } from '@app/features/tenants/components/onboarding/wizard'

const createData = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => {
  const days = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  })

  const growthRate = 1.02

  const values = []

  for (let i = 0; i < days.length; i++) {
    const dailyGrowth = Math.random() * 0.3 + 0.7

    const value = 2000 * Math.pow(growthRate, i) * dailyGrowth

    values.push({
      timestamp: days[i].getTime(),
      value: value,
    })
  }

  return values
}

type DashboardGridProps = {
  metrics: MetricProps[]
  dateRange: DateRange
}

export const percentDiff = (final: number, initial: number) => {
  return Math.round(((final - initial) / initial) * 100)
}

const metrics: MetricProps[] = [
  {
    id: 'new-tenants',
    label: 'New Tenants',
    value: '13',
    previousValue: '10',
    change: percentDiff(13, 10),
  },
  {
    id: 'churned-tenants',
    label: 'Churned Tenants',
    value: '1',
    previousValue: '2',
    change: percentDiff(1, 2),
    isIncreasePositive: false,
  },
  {
    id: 'average-onboarding-time',
    label: 'Average Onboarding Time',
    value: '2 days',
    previousValue: '2 weeks',
    change: percentDiff(2, 14),
    isIncreasePositive: false,
  },
  {
    id: 'lifetime-value-saved',
    label: 'Lifetime Value Saved',
    value: '€8,800',
    previousValue: '€1050',
    change: percentDiff(8800, 1050),
  },
]

const DashboardGrid = ({ metrics, dateRange }: DashboardGridProps) => {
  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', null, null, 'repeat(2, 1fr)']}
      gridAutoColumns="fr1"
      width="100%"
      gap={{ base: 4, xl: 8 }}
      pb="8"
    >
      <GridItem colSpan={{ base: 1, lg: 2 }} maxW="100vw">
        <Tabs variant="unstyled" tabIndex={0}>
          <TabList
            overflow="hidden"
            borderTopRadius="md"
            display="flex"
            flexWrap="wrap"
          >
            {metrics.map((metric) => (
              <Tab
                key={metric.id}
                id={metric.id}
                alignItems="stretch"
                justifyContent="stretch"
                flex={{ base: '0 0 50%', lg: '1 0 auto' }}
                height="auto"
                textAlign="left"
                borderBottomWidth="1px"
                borderRightWidth="1px"
                _hover={{
                  bg: 'whiteAlpha.100',
                  _dark: {
                    bg: 'whiteAlpha.100',
                  },
                }}
                _selected={{
                  borderBottomWidth: '2px',
                  borderBottomColor: 'primary.500',
                  display: 'flex',
                }}
                _last={{
                  borderRightWidth: '0',
                }}
              >
                <Metric {...metric} />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {metrics.map((metric) => (
              <TabPanel key={metric.id} pt="8">
                <RevenueChart
                  data={createData({
                    startDate: dateRange.start.toString(),
                    endDate: dateRange.end.toString(),
                  })}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </GridItem>
      <GridItem as={UsageByTenant} />
      <GridItem as={Activity} />
    </Grid>
  )
}

export function DashboardPage() {
  const slug = useWorkspace()
  const { tenant, isLoading, project } = useCurrentUser()

  const toolbar = (
    <Toolbar className="overview-toolbar" variant="ghost">
      <ToolbarButton
        as="a"
        href="https://schemamap.dev/"
        icon={<FaGithub />}
        label="Star on Github"
      />
      <ToolbarButton
        as="a"
        href="https://discord.com/invite/P3UzxNusbA"
        icon={<FaDiscord />}
        label="Join Discord"
      />
    </Toolbar>
  )

  const [range, setRange] = useState('30d')
  const [dateRange, setDateRange] = useState(getRangeValue('30d'))
  const onPresetChange = (preset: string) => {
    if (preset !== 'custom') {
      setDateRange(getRangeValue(preset as DateRangePresets))
    }
    setRange(preset)
  }

  const onRangeChange = (range: DateRange) => {
    const diff = getRangeDiff(range)
    if ([1, 3, 7, 30].includes(diff)) {
      setRange(`${diff}`)
    } else {
      setRange('custom')
    }

    setDateRange(range)
  }

  const footer = (
    <Toolbar
      justifyContent="flex-start"
      alignItems="center"
      variant="secondary"
      size="sm"
    >
      <SegmentedControl
        size="sm"
        segments={[
          {
            id: '1d',
            label: '1d',
          },
          {
            id: '3d',
            label: '3d',
          },
          {
            id: '7d',
            label: '7d',
          },
          { id: '30d', label: '30d' },
          { id: 'custom', label: 'Custom' },
        ]}
        value={range}
        onChange={onPresetChange}
      />
      <DateRangePicker value={dateRange} onChange={onRangeChange} />
      <ToolbarDivider />
      <HStack flex={'1'} justifyContent={'flex-end'}>
        <Icon as={FiAlertCircle} mr="1" boxSize="4" />
        <Text as={'span'}>
          Dashboard shows approximations until statistically signficant data is
          available.
        </Text>
      </HStack>
    </Toolbar>
  )

  if (!isLoading && !tenant) {
    return (
      <ErrorPage
        title="No organization found"
        description={`We couldn't find a organization named like that.`}
      />
    )
  }

  const onboardedEnvs = project?.environments?.filter(
    (env) => env.datasources?.length > 0,
  )

  const fullyOnboarded = onboardedEnvs?.length ?? 0 > 2

  return (
    <Page isLoading={isLoading}>
      <PageHeader
        title={tenant?.name}
        toolbar={toolbar}
        footer={fullyOnboarded && footer}
      />
      <PageBody pt="8" bg="page-body-bg-subtle" contentWidth={'container.2xl'}>
        {/* <IntroTour /> */}
        {fullyOnboarded ? (
          <DashboardGrid metrics={metrics} dateRange={dateRange} />
        ) : (
          <OnboardingWizard />
        )}
      </PageBody>
    </Page>
  )
}
