import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Tooltip,
} from '@chakra-ui/react'
import { useSnackbar } from '@saas-ui/react'
import {
  FiRefreshCw,
  FiPlusCircle,
  FiCheckCircle,
  FiCircle,
} from 'react-icons/fi'
import {
  SectionBody,
  SectionHeader,
  Section,
  useColumns,
  DataGrid,
  BulkActionsSelections,
  Command,
  BulkActions,
  SectionDescription,
} from '@saas-ui-pro/react'
import { RemoteListMdEsResult, TRACK_MDES, REMOTE_LIST_MDES } from '@api/client'
import { useMemo, useState } from 'react'
import React from 'react'
import { datasourceLabel } from './helper'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  Datasource,
  Environment,
} from '@app/features/core/hooks/use-current-user'

export interface MDEListingProps {
  environment: Environment
  selectedDatasource: Datasource | null
  refetch: () => void
}
export default ({
  environment,
  refetch,
  selectedDatasource,
}: MDEListingProps) => {
  const snackbar = useSnackbar()

  const [rawSyncedMDEs, updateSyncedMDEs] = useState<
    NonNullable<RemoteListMdEsResult>[]
  >([])

  const syncedMDEs = useMemo(() => {
    return rawSyncedMDEs.map((syncedMDE) => ({
      ...syncedMDE,
      tracked: Boolean(
        environment.trackedMdeEnvironments.find(
          (tte) => tte.trackedMde.shortName === syncedMDE.shortName,
        ),
      ),
    }))
  }, [environment.trackedMdeEnvironments, rawSyncedMDEs])

  const [listMDEs, { loading: isLoadingMDEs, error }] = useLazyQuery(
    REMOTE_LIST_MDES,
    {
      onCompleted: (data) => {
        const filteredMDEs = data?.listMDEs?.filter(
          Boolean,
        ) as RemoteListMdEsResult[]
        if (filteredMDEs) {
          updateSyncedMDEs(filteredMDEs)

          snackbar.success({
            description: `Fetched ${filteredMDEs.length} Master Data Entities`,
          })
        }
      },
      onError: (data) => {
        snackbar.error({
          description:
            'Failed to fetch Master Data Entities, check connectivity',
        })
      },
    },
  )

  const [mutateTrackMDEs, { loading: isCreatingTrackedMDEs }] = useMutation(
    TRACK_MDES,
    {
      onCompleted: (data) => {
        refetch()
        snackbar.success({
          description: `Tracked ${data?.insertTrackedMdes?.returning.length} MDE(s)`,
        })
      },
      onError: (data) => {
        snackbar.error({ description: 'Failed to track MDE(s)' })
      },
    },
  )

  const createTrackedMDEs = (selections: string[] | number[]) => {
    const selectedSyncedMDEs = selections.map((i) => syncedMDEs[Number(i)])
    mutateTrackMDEs({
      variables: {
        trackedMdes: selectedSyncedMDEs.map((t) => ({
          tenantId: selectedDatasource?.tenantId,
          projectId: environment.project?.id,
          name: t?.name,
          shortName: t?.shortName,
          trackedMdeEnvironments: {
            data: [
              {
                tenantId: selectedDatasource?.tenantId,
                environmentId: environment.id,
              },
            ],
          },
        })),
      },
    })
  }

  const bulkActions = ({
    selections,
  }: {
    selections: BulkActionsSelections
  }) => (
    <>
      <Tooltip
        placement="top"
        label={
          <>
            Track all Master Data Entities <Command>⇧ T</Command>
          </>
        }
      >
        <Button
          onClick={() => {
            createTrackedMDEs(selections)
          }}
          leftIcon={<FiPlusCircle size="1em" />}
        >
          Track all
        </Button>
      </Tooltip>
    </>
  )

  type SyncedMDEs = (typeof syncedMDEs)[0]

  const columns = useColumns<SyncedMDEs>((helper) => {
    return [
      helper.accessor('name', {
        header: 'Name',
        size: 200,
      }),
      helper.accessor('shortName', { header: 'Short Name' }),
      helper.accessor('tracked', {
        header: 'Tracked',
        cell: (cell) => (cell.getValue() ? <FiCheckCircle /> : <FiCircle />),
        size: 120,
      }),
      helper.display({
        id: 'action',
        header: 'Actions',
        cell: (cell) =>
          cell.row.getValue('tracked') || (
            <Button
              size="xs"
              onClick={() => {
                createTrackedMDEs([cell.row.index])
              }}
            >
              Track MDE
            </Button>
          ),
        enableGlobalFilter: false,
        enableHiding: false,
        enableSorting: false,
      }),
    ]
  }, [])

  const [selections, setSelections] = React.useState<string[]>([])

  const onSelectedRowsChange = React.useCallback((rows: string[]) => {
    setSelections(rows)
  }, [])

  const syncingButtons = useMemo(() => {
    return (
      <ButtonGroup>
        <Button
          colorScheme="primary"
          variant="solid"
          isDisabled={isLoadingMDEs || selectedDatasource == null}
          leftIcon={<FiRefreshCw />}
          onClick={() => {
            if (selectedDatasource)
              listMDEs({
                variables: { datasourceId: selectedDatasource.id.toString() },
              })
          }}
        >
          Sync MDEs
        </Button>
      </ButtonGroup>
    )
  }, [isLoadingMDEs, selectedDatasource, listMDEs])

  const emptyState = React.useMemo(() => {
    if (syncedMDEs.length == 0) {
      if (!error) {
        return (
          <Card>
            <CardHeader color="muted">No synced MDEs found.</CardHeader>
            <CardBody>{syncingButtons}</CardBody>
          </Card>
        )
      } else if (selectedDatasource) {
        return (
          <Card>
            <CardHeader color="muted">
              Press the button below to fetch the MDEs via datasource:{' '}
              {datasourceLabel(selectedDatasource)}.
            </CardHeader>
            <CardBody>{syncingButtons}</CardBody>
          </Card>
        )
      }
    }
  }, [error, selectedDatasource, syncedMDEs.length, syncingButtons])

  return (
    <Section variant="annotated">
      <SectionHeader
        maxW={'sm'}
        title="Master Data Entity Listing"
        description={
          <SectionDescription>
            <Box>Check which MDES are present in this environment.</Box>
            <Box>These will be used for Google Sheet syncing.</Box>
          </SectionDescription>
        }
      />
      <SectionBody>
        {emptyState || (
          <Card>
            <DataGrid
              columns={columns}
              data={syncedMDEs}
              isSortable
              isSelectable
              isHoverable
              onSelectedRowsChange={onSelectedRowsChange}
            />
            <BulkActions
              selections={selections}
              variant="floating"
              motionPreset="slideOutBottom"
              colorScheme="gray"
              title=":selections MDEs selected"
            >
              {bulkActions}
            </BulkActions>
            <Box mt={8} mb={4} ml={4}>
              {syncingButtons}
            </Box>
          </Card>
        )}
      </SectionBody>
    </Section>
  )
}
