import {
  Card,
  CardBody,
  Button,
  Heading,
  Text,
  Code,
  Stack,
  VStack,
  Link,
} from '@chakra-ui/react'

// import 'monaco-sql-languages/out/esm/pgsql/pgsql.contribution'
import Editor from '@monaco-editor/react'
import slug from 'slug'
import { useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { ASSIGN_PG_TUNNEL_TO_ENVIRONMENT } from '@api/client'
import { useSnackbar } from '@saas-ui/react'
import {
  Environment,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'

export interface SetupPgTunnelProps {
  environment: Environment
}

export const ratholeConfigFileName = (
  environment: Environment,
  port: number,
) => {
  const parts = [
    'schemamap',
    environment.project?.name ? slug(environment.project?.name) : undefined,
    environment.name ? slug(environment.name) : undefined,
    'pgtunnel',
    port,
  ].filter(Boolean)

  return `${parts.join('-')}.toml`
}

export const SetupPgTunnel: React.FC<SetupPgTunnelProps> = ({
  environment,
}) => {
  const snackbar = useSnackbar()
  const { pgtunnelPorts } = environment
  const { invalidate } = useCurrentUser()
  const pgtunnelPort = pgtunnelPorts.length > 0 ? pgtunnelPorts[0] : undefined

  const [assignPgTunnelPort, { loading }] = useMutation(
    ASSIGN_PG_TUNNEL_TO_ENVIRONMENT,
    {
      onCompleted: (data) => {
        invalidate()
        snackbar.success(`Tunnel port reserved! 🎉`)
      },
      onError: (error) => {
        snackbar.error(error.message)
      },
    },
  )

  const ratholeTomlFile = useMemo(
    () =>
      pgtunnelPort?.port
        ? ratholeConfigFileName(environment, pgtunnelPort?.port)
        : '',
    [environment, pgtunnelPort?.port],
  )

  return (
    <Card mb={4}>
      <CardBody>
        <Stack
          spacing={4}
          direction={['column', null, null, null, null, 'row']}
        >
          <VStack alignItems={'flex-start'} minWidth={'300'} flex={1}>
            <Heading size="md">Establishing connectivity</Heading>
            <Text>
              Schemamap.io needs to connect to your Postgres database to be able
              to interact with the <Code>schemamap</Code> SDK.
            </Text>
            <Text>
              To allow connecting from local environments, and where exposing
              Postgres to the Internet is not feasible, we use{' '}
              <Code>
                <Link isExternal href="https://github.com/schemamap/schemamap">
                  schemamap up
                </Link>
              </Code>{' '}
              command as a secure TCP P2P tunneling solution.
            </Text>
            <Text textColor={'muted'}>
              Schemamap.io can of course connect directly over the internet
              (with SSL required) in which case the current IPs to whitelist
              are: <Code>{process.env.NEXT_PUBLIC_DB_WHITELIST_IPS}</Code>
            </Text>

            <Text>
              The code area shows how to set up the tunnel, once reserved.
            </Text>
            <Text>
              You should add the schemamap command to your development
              environment, so you can easily configure the SDK locally, whenever
              needed.
            </Text>
            {!pgtunnelPort && (
              <Button
                size="md"
                variant="primary"
                isLoading={loading}
                onClick={() => {
                  assignPgTunnelPort({
                    variables: {
                      environmentId: environment.id,
                    },
                  })
                }}
              >
                Reserve a tunnel for this environment
              </Button>
            )}
          </VStack>
          <Editor
            width={'700px'}
            key={pgtunnelPort?.id + ratholeTomlFile}
            height="280px"
            defaultLanguage="shell"
            beforeMount={(monaco) => {
              setTimeout(() => {
                monaco.editor.setTheme('vs-dark')
              }, 1)
            }}
            defaultValue={(pgtunnelPort
              ? [
                  'brew install schemamap',
                  '# or',
                  "nix profile install 'nixpkgs#schemamap'",
                  '# or use: https://hub.docker.com/r/schemamap/postgres',
                  '# which has the CLI included',
                  '',
                  '# then run:',
                  `schemamap up -f ${ratholeTomlFile}`,
                  ``,
                  `# ${ratholeTomlFile}`,
                  '[client]',
                  `remote_addr = "pgtunnel.eu.schemamap.io:2333"`,
                  '',
                  `[client.services.postgres-${pgtunnelPort.port}]`,
                  `token = "${pgtunnelPort.token}" # secret, do not share`,
                  `local_addr = "127.0.0.1:5432" # The postgres port to be forwarded`,
                ]
              : [
                  '# Please reserve a tunnel port for this environment first.',
                  "# It's free!",
                ]
            ).join('\n')}
            options={{
              theme: 'vs-dark',
              minimap: { enabled: false },
              autoDetectHighContrast: false,
              lineNumbers: 'on',
              wordWrap: 'on',
              scrollBeyondLastLine: false,
              automaticLayout: true,
            }}
          />
        </Stack>
      </CardBody>
    </Card>
  )
}
