import * as React from 'react'

import {
  D2LodSvg,
  PostgresEnvironmentSyncPreview,
  PreviewPostgresEnvironmentSyncMutationVariables,
  SyncPostgresEnvMutation,
  SyncPostgresEnvMutationVariables,
} from '@api/client'
import { LoadingOverlay, LoadingSpinner, useSnackbar } from '@saas-ui/react'

import { Button, HStack, VStack, useClipboard } from '@chakra-ui/react'
import { Toolbar, ToolbarButton } from '@saas-ui-pro/react'
import { PgEnvSyncSteps } from './helper'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'
import { useMeasure } from '@react-hookz/web'
import { FiArrowRight, FiCopy, FiDownload, FiRefreshCw } from 'react-icons/fi'
import type { MutationFunctionOptions } from '@apollo/client'
import { Datasource } from '@app/features/core/hooks/use-current-user'

type PostgresEnvSyncReviewCommonProps = {
  preview: Omit<
    PostgresEnvironmentSyncPreview,
    'flow' | 'd2Text' | 'd2Svg'
  > | null
  isSyncingPgEnv: boolean
  sourceWhere: string
  sync: (
    options: MutationFunctionOptions<
      SyncPostgresEnvMutation,
      SyncPostgresEnvMutationVariables
    >,
  ) => Promise<any>
}

export type PostgresEnvSyncReviewProps = PostgresEnvSyncReviewCommonProps

export type SvgPreviewProps = {
  svg?: string
  lodSvgConfigs?: D2LodSvg[]
  children?: React.ReactNode
}

export const SvgPreview: React.FC<SvgPreviewProps> = ({
  svg,
  children,
  lodSvgConfigs,
}) => {
  const defaultSvgSize = 800

  const Viewer = React.useRef<UncontrolledReactSVGPanZoom>(null)

  const [currentSvg, setCurrentSVG] = React.useState<string>(
    svg ?? lodSvgConfigs?.[0]?.d2Svg ?? '',
  )

  const [domRect, ref] = useMeasure<HTMLDivElement>()

  React.useLayoutEffect(() => {
    Viewer.current?.fitToViewer()
  }, [])

  const width = domRect?.width ?? defaultSvgSize
  const height = domRect?.height ?? defaultSvgSize

  return (
    <HStack alignItems={'stretch'} height={'full'} width={'full'} ref={ref}>
      <UncontrolledReactSVGPanZoom
        width={width}
        height={height}
        ref={Viewer}
        onZoom={(x) => {
          if (!lodSvgConfigs) return
          const scaleFactor = -(x as any).f
          for (let i = lodSvgConfigs.length - 1; i >= 0; i--) {
            if (scaleFactor >= lodSvgConfigs[i].lod) {
              const newSvg = lodSvgConfigs[i].d2Svg
              if (newSvg !== currentSvg) {
                setCurrentSVG(newSvg)
              }
              break
            }
          }
        }}
        scaleFactorOnWheel={1.01}
        preventPanOutside
        detectAutoPan={false}
        defaultTool={'pan'}
        miniatureProps={{
          position: 'none',
          background: 'none',
          width: 0,
          height: 0,
        }}
        background="#1E1E2E"
        SVGBackground="#1E1E2E"
      >
        <svg width={width} height={height}>
          <g dangerouslySetInnerHTML={{ __html: currentSvg }} />
        </svg>
      </UncontrolledReactSVGPanZoom>
      {children}
    </HStack>
  )
}

export const PostgresEnvSyncReview = ({
  preview,
}: PostgresEnvSyncReviewProps) => {
  const snackbar = useSnackbar()
  const lastLodSvg = preview
    ? preview.d2LODSvgs[preview.d2LODSvgs.length - 1]
    : null
  const d2Svg = lastLodSvg?.d2Svg ?? ''
  const d2Text = lastLodSvg?.d2Text
  const {
    setValue: setD2TextCopy,
    onCopy,
    hasCopied,
  } = useClipboard(d2Text ?? '')
  if (!preview)
    return (
      <LoadingOverlay>
        <LoadingSpinner />
      </LoadingOverlay>
    )

  return (
    <SvgPreview lodSvgConfigs={preview.d2LODSvgs}>
      <VStack
        position={'absolute'}
        alignItems={'stretch'}
        bottom={2}
        right={2}
        spacing={1}
      >
        {d2Text && (
          <Button
            leftIcon={<FiCopy />}
            onClick={() => {
              onCopy()
              snackbar.info('Copied D2 text to clipboard')
            }}
          >
            Copy D2 text
          </Button>
        )}
        <Button
          leftIcon={<FiDownload />}
          onClick={() => {
            const blob = new Blob([d2Svg], { type: 'image/svg+xml' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'schema-map.svg'
            a.click()
            URL.revokeObjectURL(url)
          }}
        >
          Download SVG
        </Button>
      </VStack>
    </SvgPreview>
  )
}

type PreviewEnvSyncType = {
  variables: PreviewPostgresEnvironmentSyncMutationVariables
}

type PostgresEnvSyncToolbarProps = PostgresEnvSyncReviewCommonProps & {
  previewEnvSync: (options: PreviewEnvSyncType) => Promise<any>
  setSelectedMDE: (mde: string) => void
  setCurrentStep: (step: PgEnvSyncSteps) => void
  isLoadingPreview: boolean
  calculatePreview: () => void

  selectedSourceDatasource: Datasource | null
  selectedTargetDatasource: Datasource | null
  selectedMDE: string | undefined
  selectedTrackedTenant: string | undefined
  sourceWhere: string
}

export const PostgresEnvSyncReviewToolbar = ({
  preview,
  calculatePreview,
  setCurrentStep,
  isLoadingPreview,
  isSyncingPgEnv,
  selectedMDE,
  selectedSourceDatasource,
  selectedTrackedTenant,
  selectedTargetDatasource,
  sync,
  sourceWhere,
}: PostgresEnvSyncToolbarProps) => {
  const snackbar = useSnackbar()

  const primaryAction = (
    <ToolbarButton
      label="Sync"
      disabled={!preview || isSyncingPgEnv}
      rightIcon={<FiArrowRight />}
      variant={'primary'}
      onClick={async () => {
        if (!preview || !selectedSourceDatasource || !selectedTargetDatasource)
          return
        snackbar.promise(
          sync({
            variables: {
              trackedMdeId: selectedMDE,
              sourceDatasourceId: selectedSourceDatasource.id,
              targetDatasourceId: selectedTargetDatasource.id,
              sourceTrackedTenantId: selectedTrackedTenant,
              targetTrackedTenantId: selectedTrackedTenant,
              sourceMdeWhere: sourceWhere,
            },
          }).then(() => {
            setCurrentStep('sync')
          }),
          {
            loading: 'Syncing...',
            success: 'Synced successfully! 🎉',
            error: (e) =>
              'Could not sync. Please try again. Reason: ' + e.message,
          },
        )
      }}
    />
  )

  return (
    <Toolbar alignItems={'center'}>
      <ToolbarButton
        label="Reload preview"
        leftIcon={<FiRefreshCw />}
        isLoading={isLoadingPreview}
        onClick={calculatePreview}
      />

      {primaryAction}
    </Toolbar>
  )
}
