import { DataGrid, ColumnDef, DataGridCell } from '@saas-ui-pro/react'
import { Center, HStack, Progress, Text } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { MetricsCard } from './metrics-card'
import { LoadingSpinner } from '@saas-ui/react'
import { GET_USAGE_BY_TRACKED_TENANTS } from '@api/client'
import { useMemo } from 'react'
import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { useQuery } from '@apollo/client'

const getPercentage = (value: number, total: number) => {
  return Math.round((100 / total) * value)
}

export type Data = {
  name: string
  syncs: number
  syncTotal: number
  savingsTotal: number
}

const SyncsCell: DataGridCell<Data> = (cell) => {
  const total = 0
  return (
    <HStack justifyContent="space-between">
      <Progress
        value={
          getPercentage(cell.getValue<number>(), cell.row.original.syncTotal) ||
          0
        }
        size="xs"
        colorScheme="primary"
        width="60px"
      />
      <Text>{cell.getValue<string>()}</Text>
    </HStack>
  )
}

const CurrencyCell: DataGridCell<Data> = ({ getValue }) => {
  const intl = useIntl()

  return (
    <>
      {intl.formatNumber(getValue<number>(), {
        currency: 'EUR',
        style: 'currency',
        maximumFractionDigits: 0,
      })}
    </>
  )
}

const columns: ColumnDef<Data>[] = [
  {
    id: 'name',
    header: 'Tenant',
  },
  {
    id: 'syncs',
    header: 'Syncs',
    cell: SyncsCell,
    meta: {
      isNumeric: true,
    },
    size: 100,
  },
  {
    id: 'savingsTotal',
    header: 'Savings Total',
    cell: CurrencyCell,
    meta: {
      isNumeric: true,
    },
    size: 100,
  },
]

export const UsageByTenant = () => {
  const { projectId } = useCurrentUser()
  const { data, loading: isLoading } = useQuery(GET_USAGE_BY_TRACKED_TENANTS, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      projectId: projectId!,
    },
    skip: !projectId,
  })

  const transformedData: Data[] | undefined = useMemo(() => {
    const almostReadyData = data?.trackedTenants.map((tenant) => {
      const syncs = tenant.googleSpreadsheets.reduce((sum, sheet) => {
        return (
          sum + (sheet?.googleSpreadsheetSyncsAggregate?.aggregate?.count || 0)
        )
      }, 0)
      const tenantData = {
        name: tenant.name,
        syncs,
        // TODO: calculate savingsTotal
        savingsTotal: syncs * (100 + Math.random() * 100),
      }

      return tenantData
    })

    const syncTotal =
      almostReadyData?.reduce((sum, tenant) => {
        return sum + tenant.syncs
      }, 0) || 0

    return almostReadyData?.map((tenant) => {
      return {
        ...tenant,
        syncTotal,
      }
    })
  }, [data?.trackedTenants])

  return (
    <MetricsCard title="Usage by tenant" noPadding>
      <Center color="muted" textAlign={'center'} minH={'100'}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (data?.trackedTenants?.length || 0) === 0 ? (
          <Text color="muted">
            No tracked tenants available.
            <br />
            Track them for any of your projects Postgres datasources.
          </Text>
        ) : (
          <DataGrid<Data>
            columns={columns}
            data={transformedData || []}
            isSortable
          />
        )}
      </Center>
    </MetricsCard>
  )
}
