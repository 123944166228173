import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Code,
  Tooltip,
} from '@chakra-ui/react'
import { useSnackbar } from '@saas-ui/react'
import {
  FiRefreshCw,
  FiPlusCircle,
  FiCheckCircle,
  FiCircle,
} from 'react-icons/fi'
import {
  SectionBody,
  SectionHeader,
  Section,
  useColumns,
  DataGrid,
  BulkActionsSelections,
  Command,
  BulkActions,
} from '@saas-ui-pro/react'
import {
  REMOTE_LIST_TENANTS,
  RemoteListTenantsResult,
  TrackedTenantEnvironmentsConstraint,
  TRACK_TENANTS,
} from '@api/client'
import { useMemo, useState } from 'react'
import React from 'react'
import { datasourceLabel } from './helper'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  Datasource,
  Environment,
} from '@app/features/core/hooks/use-current-user'

export interface TenantsListingProps {
  environment: Environment
  selectedDatasource: Datasource | null
  refetch: () => void
}
export default ({
  environment,
  selectedDatasource,
  refetch,
}: TenantsListingProps) => {
  const snackbar = useSnackbar()

  const [rawSyncedTenants, updateSyncedTenants] = useState<
    NonNullable<RemoteListTenantsResult>[]
  >([])

  const syncedTenants = useMemo(() => {
    return rawSyncedTenants.map((syncedTenant) => ({
      ...syncedTenant,
      tracked: Boolean(
        environment.trackedTenantEnvironments.find(
          (tte) => tte.trackedTenant.shortName === syncedTenant.shortName,
        ),
      ),
    }))
  }, [environment.trackedTenantEnvironments, rawSyncedTenants])

  const [listTenants, { loading: isLoadingTenants }] = useLazyQuery(
    REMOTE_LIST_TENANTS,
    {
      onCompleted: (data) => {
        const filteredTenants = data?.listTenants?.filter(
          Boolean,
        ) as RemoteListTenantsResult[]
        if (filteredTenants) {
          updateSyncedTenants(filteredTenants)

          snackbar.success({
            description: `Fetched ${filteredTenants.length} tenant(s)`,
          })
          onSelectedRowsChange([])
        }
      },
      onError: (data) => {
        snackbar.error({
          description: 'Failed to fetch tenants, check connectivity',
        })
      },
    },
  )

  const [mutateTrackTenants, { loading: isCreatingTrackedTenants }] =
    useMutation(TRACK_TENANTS, {
      onCompleted: (data) => {
        refetch()
        snackbar.success({
          description: `Tracked ${data?.insertTrackedTenants?.returning.length} tenant(s)`,
        })
      },
      onError: (data) => {
        snackbar.error({ description: 'Failed to track tenants' })
      },
    })

  const createTrackedTenants = (
    selectedSyncedTenants: RemoteListTenantsResult[],
  ) => {
    mutateTrackTenants({
      variables: {
        trackedTenants: selectedSyncedTenants.map((t) => ({
          tenantId: selectedDatasource?.tenantId,
          projectId: environment.project?.id,
          name: t.name,
          shortName: t?.shortName,
          trackedTenantEnvironments: {
            data: [
              {
                tenantId: selectedDatasource?.tenantId,
                environmentId: environment.id,
                lastKnownTenantId: t.id,
                tenantData: t.data,
                tenantLocale: t.locale,
              },
            ],
            onConflict: {
              constraint:
                TrackedTenantEnvironmentsConstraint.TrackedTenantEnvironmentsEnvironmentIdTrackedTenantIKey,
              updateColumns: [
                'lastSyncedAt',
                'lastKnownTenantId',
                'tenantLocale',
                'tenantData',
              ],
            } as any,
          },
        })),
      },
    })
  }

  const bulkActions = ({
    selections,
  }: {
    selections: BulkActionsSelections
  }) => (
    <>
      <Tooltip
        placement="top"
        label={
          <>
            Track all tenants <Command>⇧ T</Command>
          </>
        }
      >
        <Button
          onClick={() => {
            createTrackedTenants(
              selections.map((i) => syncedTenants[Number(i) as number]),
            )
          }}
          leftIcon={<FiPlusCircle size="1em" />}
        >
          Track all
        </Button>
      </Tooltip>
    </>
  )

  type SyncedTenants = (typeof syncedTenants)[0]

  const columns = useColumns<SyncedTenants>((helper) => {
    return [
      helper.accessor('id', {
        header: 'ID',
        size: 100,
      }),
      helper.accessor('name', {
        header: 'Name',
        size: 200,
      }),
      helper.accessor('shortName', { header: 'Short Name', size: 150 }),
      helper.accessor('locale', { header: 'Locale', size: 100 }),
      helper.accessor('data', {
        header: 'Data',
        cell: (cell) => <Code>{cell.getValue()?.toString()}</Code>,
        size: 200,
      }),
      helper.accessor('tracked', {
        header: 'Tracked',
        cell: (cell) => (cell.getValue() ? <FiCheckCircle /> : <FiCircle />),
        size: 120,
      }),
      helper.display({
        id: 'action',
        header: 'Actions',

        cell: (cell) => (
          <Button
            size="xs"
            isDisabled={!cell.row.getValue('shortName')}
            onClick={() => {
              createTrackedTenants([cell.row.original])
            }}
          >
            Track
          </Button>
        ),
        enableGlobalFilter: false,
        enableHiding: false,
        enableSorting: false,
      }),
    ]
  }, [])

  const [selections, setSelections] = React.useState<string[]>([])

  const onSelectedRowsChange = React.useCallback((rows: string[]) => {
    setSelections(rows)
  }, [])

  const syncingButtons = useMemo(() => {
    return (
      <ButtonGroup>
        <Button
          colorScheme="primary"
          variant="solid"
          disabled={isLoadingTenants || selectedDatasource == null}
          leftIcon={<FiRefreshCw />}
          onClick={() => {
            if (selectedDatasource)
              listTenants({
                variables: { datasourceId: selectedDatasource.id.toString() },
              })
          }}
        >
          Sync tenants
        </Button>
      </ButtonGroup>
    )
  }, [isLoadingTenants, selectedDatasource, listTenants])

  const emptyState = React.useMemo(() => {
    if (syncedTenants.length == 0) {
      if (!isLoadingTenants) {
        return (
          <Card>
            <CardHeader color="muted">No synced tenants found.</CardHeader>
            <CardBody>{syncingButtons}</CardBody>
          </Card>
        )
      } else if (selectedDatasource) {
        return (
          <Card>
            <CardHeader color="muted">
              Press the button below to fetch the tenants via datasource:{' '}
              {datasourceLabel(selectedDatasource)}.
            </CardHeader>
            <CardBody>{syncingButtons}</CardBody>
          </Card>
        )
      }
    }
  }, [
    isLoadingTenants,
    selectedDatasource,
    syncedTenants.length,
    syncingButtons,
  ])

  return (
    <Section variant="annotated">
      <SectionHeader
        maxW={'sm'}
        title="Tenants Listing"
        description="Check which tenants are present in this environment."
      />
      <SectionBody>
        {emptyState || (
          <Card>
            <DataGrid
              columns={columns}
              data={syncedTenants}
              isSortable
              isSelectable
              isHoverable
              onSelectedRowsChange={onSelectedRowsChange}
            />
            <BulkActions
              selections={selections}
              variant="floating"
              motionPreset="slideOutBottom"
              colorScheme="gray"
              title=":selections tenants selected"
            >
              {bulkActions}
            </BulkActions>
            <Box mt={8} mb={4} ml={4}>
              {syncingButtons}
            </Box>
          </Card>
        )}
      </SectionBody>
    </Section>
  )
}
