import { DateTime, RelativeTime } from '@app/i18n'
import { Text, Tooltip } from '@chakra-ui/react'
import {
  Timeline,
  TimelineContent,
  TimelineIcon,
  TimelineItem,
  TimelineSeparator,
  TimelineTrack,
} from '@saas-ui/react'
import { MetricsCard } from './metrics-card'
import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { useMemo } from 'react'

const ActivityDate: React.FC<{ date: Date }> = (props) => {
  return (
    <Tooltip label={<DateTime date={props.date} />}>
      <Text as="span" color="muted">
        <RelativeTime date={props.date} />
      </Text>
    </Tooltip>
  )
}

type ActivityItem = {
  name: string
  action: string
  date: Date
}

export const Activity = () => {
  const { user } = useCurrentUser()

  // TODO: fetch this from the API

  const actv = useMemo<ActivityItem[]>(() => {
    return [
      {
        name: user?.fullName ?? 'Someone',
        action: 'viewed the dashboard',
        date: new Date(),
      },
    ]
  }, [user?.fullName])

  return (
    <MetricsCard title="Activity">
      <Timeline variant="outline">
        {actv.map(({ name, action, date }, i) => (
          <TimelineItem key={i}>
            <TimelineSeparator>
              <TimelineIcon />
              {i < actv.length - 1 && <TimelineTrack />}
            </TimelineSeparator>
            <TimelineContent>
              <strong>{name}</strong> <span>{action}</span>.{' '}
              <ActivityDate date={date} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </MetricsCard>
  )
}
